<template>
  <div>
    <div class="px-16 sticky-header">
      <div class="d-flex mb-8 page-subheader">
        <div class="d-flex align-center">
          <div class="secondary--text custom-heading mr-12">Evictions</div>
          <v-btn
            fab
            depressed
            color="primary"
            height="45"
            width="45"
            @click="startNewCase"
          >
            <span
              class="white--text font-weight-regular"
              style="font-size: 50px"
              >+</span
            >
          </v-btn>
        </div>

        <div class="d-flex pl-8 mt-4 ml-auto">
          <!-- search start -->
          <v-menu
            v-model="searchMenu"
            :close-on-content-click="false"
            :nudge-width="200"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="px-4 subheader-btn" v-bind="attrs" v-on="on">
                <i class="icofont-search-1" color="grey" />&nbsp;Search
              </v-btn>
            </template>

            <v-card
              style="box-shadow: 15px 15px 40px #00000029; border-radius: 20px"
            >
              <div class="px-8 py-4 table-custom-search">
                <input
                  v-model="search"
                  type="text"
                  placeholder="Search Cases"
                />
              </div>
            </v-card>
          </v-menu>
          <!-- search end -->

          <!-- export start -->
          <v-btn class="ml-4 px-4 subheader-btn" @click="exportExcel">
            <i class="icofont-external" />&nbsp;Export
          </v-btn>
          <!-- export end -->

          <!-- filter start -->
          <v-menu
            v-model="filterMenu"
            :close-on-content-click="false"
            :nudge-width="530"
            offset-x
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="ml-4 px-4 subheader-btn" v-bind="attrs" v-on="on">
                <v-icon size="xx-large" color="primary"
                  >icofont-dotted-down</v-icon
                >&nbsp;Filter By:
              </v-btn>
            </template>

            <v-card
              style="box-shadow: 15px 15px 40px #00000029; border-radius: 20px"
            >
              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <strong class="secondary--text">FILTER BY</strong>
                  </v-list-item-content>
                </v-list-item>
              </v-list>

              <v-divider></v-divider>

              <v-list>
                <v-list-item v-for="(item, index) in filters" :key="index">
                  <v-list-item-action>
                    <v-icon @click="removeFilter(index)">mdi-close</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-select
                      v-model="item.filterColumn"
                      :items="item.columns"
                      class="mr-2"
                      solo
                      flat
                      dense
                      background-color="#F0F5F6"
                      style="max-width: 200px"
                    >
                      <v-icon slot="append" medium color="accent">
                        mdi-chevron-down
                      </v-icon>
                    </v-select>
                    <v-select
                      v-model="item.filterOperator"
                      :items="item.operators"
                      class="mr-2"
                      solo
                      flat
                      dense
                      background-color="#F0F5F6"
                      style="max-width: 150px"
                    >
                      <v-icon slot="append" medium color="accent">
                        mdi-chevron-down
                      </v-icon>
                    </v-select>
                    <v-menu
                      v-if="item.filterColumn === 'DATE ADDED'"
                      v-model="dateAddedMenu"
                      :close-on-content-click="false"
                      offset-x
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          :value="
                            item.filterValue
                              ? $dayjs(item.filterValue).format('MM/DD/YYYY')
                              : $dayjs().format('MM/DD/YYYY')
                          "
                          v-bind="attrs"
                          v-on="on"
                          append-icon="mdi-calendar"
                          class="mr-4"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="item.filterValue"
                        no-title
                        @input="dateAddedMenu = false"
                      ></v-date-picker>
                    </v-menu>
                    <v-select
                      v-else
                      v-model="item.filterValue"
                      :items="item.values"
                      solo
                      flat
                      dense
                      background-color="#F0F5F6"
                      style="max-width: 200px"
                    >
                      <v-icon slot="append" medium color="accent">
                        mdi-chevron-down
                      </v-icon>
                    </v-select>
                  </v-list-item-content>
                </v-list-item>
              </v-list>

              <v-card-actions>
                <v-btn
                  class="btn--plain ml-12"
                  color="secondary"
                  icon
                  :disabled="!cases.length"
                  @click="addFilter"
                >
                  <v-icon class="mr-2" color="accent" style="font-size: 28px"
                    >mdi-plus-circle-outline</v-icon
                  >
                  Add Filter
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
          <v-badge v-if="filterCount > 0" color="primary" overlap>
            <span slot="badge" style="font-size: 17px; font-weight: bold">{{
              filterCount
            }}</span>
          </v-badge>
          <!-- filter end -->
        </div>
      </div>
    </div>

    <EvictionTable :search="search" :filters="filters" ref="evictionTable" />
  </div>
</template>

<script>
import EvictionTable from "@/components/tables/EvictionTable";
import { mapGetters } from "vuex";
import { combineTenants } from "@/utils";

export default {
  name: "Main",
  components: {
    EvictionTable,
  },
  data() {
    return {
      search: "",
      searchMenu: false,
      filters: [
        {
          columns: [
            "CASE ID",
            "DATE ADDED",
            "ATTORNEY",
            "CLIENT",
            "PARTIES",
            "COUNTY",
            "DOCKET NO.",
            "STATUS",
          ],
          operators: [],
          values: [],
          filterColumn: "",
          filterOperator: "",
          filterValue: "",
        },
      ],
      filterMenu: false,
      tableData: [],
      dateAddedMenu: false,
    };
  },
  computed: {
    ...mapGetters({
      cases: "eviction/cases",
    }),
    filterColumns() {
      return this.filters.map((item) => item.filterColumn);
    },
    filterCount() {
      return this.filters.filter(
        (item) => item.filterColumn && item.filterOperator && item.filterValue
      ).length;
    },
  },
  watch: {
    filterColumns(newVal, oldVal) {
      if (newVal.length !== oldVal.length) return;
      const diffItem = newVal.find((item, index) => item !== oldVal[index]);
      const diffIndex = newVal.indexOf(diffItem);
      if (this.filters[diffIndex].filterColumn === "DATE ADDED") {
        this.filters[diffIndex].operators = [
          "On or Before",
          "On or After",
          "On",
        ];
        this.filters[diffIndex].filterValue = null;
      } else {
        this.filters[diffIndex].operators = ["Equal To"];
        this.filters[diffIndex].filterOperator = "Equal To";
        this.filters[diffIndex].filterValue = "";
        this.filters[diffIndex].values = this.tableData.map(
          (item) => item[this.filters[diffIndex].filterColumn]
        );
      }
    },
  },
  created() {
    this.cases.map((item) => {
      this.tableData.push({
        "CASE ID": `${item.attorneyInfo.firstName.slice(
          0,
          3
        )}-${item.clientInfo.firstName.slice(0, 3)}-${item.id}`.toUpperCase(),
        "DATE ADDED": this.$dayjs(item.created_at).format("DD/MM/YYYY"),
        ATTORNEY: `${item.attorneyInfo.firstName} ${item.attorneyInfo.lastName}`,
        CLIENT: `${item.clientInfo.firstName} ${item.clientInfo.lastName}`,
        PARTIES:
          `${item.deedNames} vs. ` + combineTenants(item.tenants) ?? "Pending",
        COUNTY: item.county,
        "DOCKET NO.": item.docketNo ?? "LT-",
        STATUS: item.isDeleted,
      });
    });
  },
  methods: {
    exportExcel() {
      this.$refs.evictionTable.exportExcel();
    },
    removeFilter(index) {
      this.filters.splice(index, 1);
    },
    addFilter() {
      this.filters.push({
        columns: [
          "CASE ID",
          "DATE ADDED",
          "ATTORNEY",
          "CLIENT",
          "PARTIES",
          "COUNTY",
          "DOCKET NO.",
          "STATUS",
        ],
        operators: [],
        values: [],
        filterColumn: "",
        filterOperator: "",
        filterValue: "",
      });
    },
    startNewCase() {
      this.$store.commit("eviction/init_steps");
      this.$store.commit("eviction/init_case");
      this.$store.commit("property/init_property");
      this.$router.push("verified-complaint/new");
    },
    getCaseActionLabel(caseLine) {
      return caseLine.actions[0]?.name || "Follow-up with Court";
    },
  },
};
</script>

<style>
.sticky-header {
  background: #fff;
  position: sticky;
  top: 70px;
  z-index: 5;
}
.subheader-btn {
  border-radius: 20px;
}
.icofont-external {
  color: #dae3e9;
}
</style>

<style scoped>
.v-menu__content {
  margin-top: 40px;
  border-radius: 15px;
}
.v-select >>> .v-text-field__details {
  display: none;
}
</style>
