<template>
  <VerifiedComplaintBox
    @vcBoxContinue="next"
    @vcBoxCancel="$router.push({ name: 'case-view-overview' })"
    @vcBoxBack="goBack"
  >
    <div class="custom-title pt-8 pb-4">Let's Finalize</div>

    <div class="custom-subtitle mb-4 mt-2">Preview</div>
    <div class="mb-12">Please take a moment to review carefully.</div>
    <div
      style="
        height: 538px;
        border: 1px solid #8ba4b4;
        border-radius: 20px;
        position: relative;
        z-index: 1;
      "
      class="py-4"
    >
      <div class="magnify-icon">
        <v-icon large @click="openPDF">mdi-magnify</v-icon>
      </div>
      <PdfPreviewProgressBar :isPdfLoading="isLoading" />
      <vue-scroll :ops="ops">
        <PdfViewer v-if="pdf" :src="pdf" />
      </vue-scroll>
    </div>

    <div class="custom-subtitle mb-4 mt-12">Attorney Signature Block</div>
    <div class="pb-6">
      Based on the information you have provided, your signature block will
      appear as follows. You may edit the information as you see fit.
    </div>
    <div
      style="height: 132px; padding: 20px"
      class="signature-pad mt-6 d-flex align-center justify-center"
    >
      <EditableField
        v-model="signatureText"
        inputStyles="width: 490px;"
        valueStyles="width: 490px;"
      />
    </div>

    <div>
      <div class="custom-subtitle mb-4 mt-12">Attorney Signature</div>
      <div class="pb-6">
        <div>
          All pleadings must be properly executed. If you intend to e-file,
          provide an electronic signature below.
          <span class="font-weight-medium">
            If you intend to file in person or mail in, print the document and
            provide your original signature. <u>Do not</u> sign below.
          </span>
        </div>
      </div>
      <VueSignaturePad
        ref="signaturePad"
        height="155px"
        class="signature-pad mt-6"
      />
      <v-btn
        style="margin-top: -40px"
        text
        class="btn--plain float-right pr-2"
        color="#8BA4B4"
        @click="clear"
      >
        Clear
      </v-btn>
    </div>

    <v-dialog v-model="successDialog" persistent value="''" max-width="600">
      <InfoDialog
        color="primary"
        title="Success!"
        type="success"
        :text="successDialogText"
        btn-text="DOWNLOAD CONSENT TO ENTER JUDGMENT"
        @submit="submit"
      />
    </v-dialog>
  </VerifiedComplaintBox>
</template>

<script>
import EditableField from "@/components/EditableField.vue";
import PdfViewer from "@/components/PdfViewer.vue";
import { getAttorneySignatureText, openPDFInNewTab } from "@/utils";
import { mapGetters } from "vuex";
import useRequest from "@/utils/useRequest";
import { courtOutcomesString } from "@/utils/worFlowData";
import { VueSignaturePad } from "vue-signature-pad";
import VerifiedComplaintBox from "@components/VerifiedComplaintBox";
import getPleadingMixin from "@/mixins/get-pleading";
import viewSupport from "@/mixins/view-support";
import dayjs from "dayjs";
import PdfPreviewProgressBar from "../../PdfPreviewProgressBar.vue";

export default {
  name: "ReviewAndSign",
  components: {
    VerifiedComplaintBox,
    EditableField,
    PdfViewer,
    VueSignaturePad,
    PdfPreviewProgressBar,
  },
  mixins: [viewSupport, getPleadingMixin],
  data() {
    return {
      pdf: "",
      isLoading: false,
      signatureText: "",
      successDialog: false,
      successDialogText: "",
      ops: {
        vuescroll: {},
        scrollPanel: {},
        rail: {
          opacity: 0,
          size: "8px",
          specifyBorderRadius: false,
          gutterOfEnds: "10px",
          gutterOfSide: "0px",
        },
        bar: {
          onlyShowBarOnScroll: false,
          keepShow: true,
          background: "#4DCCC4",
          opacity: 1,
          size: "8px",
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      case: "eviction/currentCase",
      property: "property/currentProperty",
      client: "client",
    }),
  },
  async activated() {
    window.scrollTo(0, 0);
    this.pdf = null;
    this.isLoading = true;

    //* debounce after two seconds
    setTimeout(() => {
      this.renderTemplate();
      // console.error("don't forget...");
    }, 2000);

    this.signatureText =
      this.case.signatureText || getAttorneySignatureText(this.case);
  },
  deactivated() {
    this.saveData();
  },
  methods: {
    clear() {
      this.$refs.signaturePad.clearSignature();
      this.$refs.signaturePad.openSignaturePad();
    },
    async saveData() {
      const caseData = {
        signatureText: "",
      };
      if (this.signatureText !== getAttorneySignatureText(this.case)) {
        caseData.signatureText = this.signatureText;
      }
      //* Save new written signature
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      if (!isEmpty) {
        caseData.newSignatureBase64 = data;
      }

      caseData.vcDateConsentDrafted = dayjs().format("YYYY-MM-DD");

      await this.$store.dispatch("eviction/updateCase", {
        caseData,
        status: "co-signature",
      });
    },
    next() {
      this.successDialogText = `
        You can now access and download the pleading you have drafted, in the Matter Management screen.
      `;
      this.successDialog = true;
    },
    goBack() {
      if (this.case.courtOutcome === courtOutcomesString()[4]) {
        // Draft Consent to Enter Judgment - Tenant Vacates without Payment Plan
        this.$router.push({ name: "wor-move-out-date" });
      } else {
        this.$router.push({ name: "wor-additional-condition" });
      }
    },
    openPDF() {
      if (!this.pdf) return;
      const data64 = this.pdf.replace("data:application/pdf;base64,", "");
      if (data64 === "undefined") return;
      openPDFInNewTab("FileEviction | Preview", data64);
    },
    async renderTemplate() {
      console.log("renderTemplate is executed, Signature.pdf:377");
      try {
        const templateName = "consent_to_enter_judgment_preview";
        const { data } = await useRequest({
          path: "create-case-pleading-pdf",
          method: "post",
          data: {
            caseId: this.case.id,
            clientId: this.client.id,
            templateName,
          },
          throwError: true,
        });
        const linkSource = `data:application/pdf;base64,${data.fileData}`;
        this.pdf = linkSource;
      } catch (error) {
        console.log("renderTemplate error:", error);
      } finally {
        this.isLoading = false;
      }
    },
    async submit() {
      const orderInfo = {
        caseId: this.case.id,
        clientId: this.client.id,
        payment_status: "Pending",
        packageId: 1,
        flowType: "03", // Court Outcome
      };
      await this.$store.dispatch("eviction/createOrder", {
        payload: { orderInfo },
      });
      await this.saveData();

      this.$router.push({ name: "case-view-management" });
    },
  },
};
</script>

<style>
.signature-pad {
  background: #fafbfc 0% 0% no-repeat padding-box;
  border: 1px solid #8ba4b4;
  border-radius: 20px;
}
.magnify-icon {
  position: absolute;
  z-index: 10;
  top: 15px;
  right: 25px;
  cursor: pointer;
}
</style>
