import useRequest from "@/utils/useRequest";
import { combineTenants } from "@/utils";
import Vue from "vue";

export default {
  namespaced: true,
  state: {
    messages: [],
    loaded: false,
  },
  mutations: {
    set_messages(state, messages) {
      Vue.set(state, "messages", messages);
    },
    set_loaded(state, loaded) {
      Vue.set(state, "loaded", loaded);
    },
  },
  actions: {
    async loadMessages({ commit }) {
      await useRequest({
        path: "messages",
        // showLoading: true,
        onSuccess: (messages) => commit("set_messages", messages),
      });
    },
    async saveNewMessage({ commit, dispatch, rootGetters }, newMessage) {
      const { data: messages } = await useRequest({
        method: "post",
        path: "messages",
        data: newMessage,
        showLoading: true,
      });
      commit("set_messages", messages);
      const selectedClient = rootGetters["client/clients"].find(
        (item) => item.id === newMessage.clientId
      );
      const selectedCase = rootGetters["eviction/cases"].find(
        (item) => item.id === newMessage.caseId
      );
      await dispatch("property/loadProperties", newMessage.clientId, {
        root: true,
      });
      const selectedProperty = rootGetters["property/properties"].find(
        (item) => item.propertyId === selectedCase.propertyId
      );
      await useRequest({
        path: "send-message-email",
        method: "post",
        data: {
          email: selectedClient.email,
          name: `${selectedClient.firstName} ${selectedClient.lastName}`,
          caseNumber: selectedCase.docketNo,
          propertyAddr: selectedProperty.streetAddress,
          tenantName: combineTenants(selectedCase.tenants) ?? "Pending",
          subject: newMessage.subject,
          content: newMessage.content,
        },
      });
    },
    async updateMessage({ commit }, message) {
      const { data: messages } = await useRequest({
        method: "put",
        path: "messages",
        data: message,
      });
      commit("set_messages", messages);
    },
  },
  getters: {
    messages: (state) => state.messages,
    loaded: (state) => state.loaded,
    unreadMessageCount: (state) =>
      state.messages.filter(
        (item) =>
          item.status === "New" && item.recipientId === state.firm.userId
      ).length,
  },
};
