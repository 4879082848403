<template>
  <VerifiedComplaintBox
    :continue-btn-disabled="!valid"
    :vc-continue-btn-props="{
      btnText: 'Update',
    }"
    :vc-cancel-btn-props="{
      btnText: 'Go Back',
    }"
    @vcBoxContinue="next"
    @vcBoxBack="goBack"
    @vcBoxCancel="$router.push({ name: 'case-view-overview' })"
  >
    <div class="custom-title pt-8 pb-4" style="font-size: 42px">
      Filed Consent To Enter Judgment
    </div>
    <div class="pb-4">
      Enter when you executed the Consent To Enter Judgment.
    </div>

    <v-form ref="form" v-model="valid" class="pt-10 pb-4 text-left">
      <div class="text-left d-flex">
        <div class="font-weight-medium secondary--text pb-2">
          Date Executed Consent
        </div>
        <div class="ml-8 dateAndTime">
          <v-menu
            v-model="vcDateConsentFiledMenuOpen"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="vcDateConsentFiled"
                append-icon="$calendar"
                color="accent"
                readonly
                rounded
                solo
                flat
                dense
                background-color="#F0F5F6"
                v-on="on"
                @click:append="vcDateConsentFiledMenuOpen = true"
              />
            </template>
            <v-date-picker
              v-model="selectedVcDateConsentFiled"
              no-title
              :show-current="false"
              color="primary"
              class="custom-date"
              @input="closedVcDateConsentFiledMenu"
              :rules="[(v) => !!v || 'Item is required']"
            />
          </v-menu>
        </div>
      </div>
    </v-form>
  </VerifiedComplaintBox>
</template>

<script>
import { mapGetters } from "vuex";
import VerifiedComplaintBox from "@components/VerifiedComplaintBox";
import { courtOutcomesString, trialActionsString } from "@/utils/worFlowData";

export default {
  name: "FiledConsentJudgment",
  components: {
    VerifiedComplaintBox,
  },
  data: () => ({
    valid: true,
    vcDateConsentFiled: null,
    selectedVcDateConsentFiled: null,
    vcDateConsentFiledMenuOpen: false,
  }),
  computed: {
    ...mapGetters({
      currentCase: "eviction/currentCase",
    }),
  },
  async activated() {
    window.scrollTo(0, 0);
    this.selectedVcDateConsentFiled =
      this.currentCase.vcDateConsentFiled ?? this.$dayjs().format("YYYY-MM-DD");
    this.vcDateConsentFiled = this.$dayjs(
      this.selectedVcDateConsentFiled
    ).format("MMMM DD, YYYY");
    await this.$store.dispatch("eviction/loadDocuments", {
      caseId: this.currentCase.id,
      name: "",
    });
  },
  deactivated() {
    this.save();
  },
  methods: {
    next() {
      if (this.$route.name.includes("trial")) {
        switch (this.currentCase.trialAction) {
          case trialActionsString()[5]: // Draft Consent to Enter Judgment - Tenant Pays and Stays
            this.$router.push({ name: "wor-trial-payment-plan" });
            break;
          case trialActionsString()[6]: // Draft Consent to Enter Judgment - Tenant Vacates with Payment Plan
          case trialActionsString()[7]: // Draft Consent to Enter Judgment - Tenant Vacates without Payment Plan
            this.$router.push({ name: "wor-trial-move-out-date" });
            break;
        }
      } else {
        switch (this.currentCase.courtOutcome) {
          case courtOutcomesString()[2]: // Draft Consent to Enter Judgment - Tenant Pays and Stays
            this.$router.push({ name: "wor-payment-plan" });
            break;
          case courtOutcomesString()[3]: // Draft Consent to Enter Judgment - Tenant Vacates with Payment Plan
          case courtOutcomesString()[4]: // Draft Consent to Enter Judgment - Tenant Vacates without Payment Plan
            this.$router.push({ name: "wor-move-out-date" });
            break;
        }
      }
    },
    goBack() {
      if (this.$route.name.includes("trial")) {
        this.$router.push({ name: "wor-trial-action" });
      } else {
        this.$router.push({ name: "wor-court-outcome" });
      }
    },
    async save() {
      const caseData = {
        vcDateConsentFiled: this.selectedVcDateConsentFiled,
      };

      await this.$store.dispatch("eviction/updateCase", {
        caseData,
        status: "wor-filedConsentJudgment",
      });
    },
    closedVcDateConsentFiledMenu(data) {
      this.selectedVcDateConsentFiled = data;
      this.vcDateConsentFiled = this.$dayjs(
        this.selectedVcDateConsentFiled
      ).format("MMMM DD, YYYY");
      this.vcDateConsentFiledMenuOpen = false;
    },
  },
};
</script>

<style scoped>
.dateAndTime {
  display: flex;
  gap: 20px;
  width: 300px;
}
</style>
